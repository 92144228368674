<template>
    <AdminWrapper>
        <template v-slot:child>
            <!--Balance sheet -->
            <div class="w-90 center">
                <router-link
                    :to="{ name: 'AdminAccountDetails', params: { id: $route.params.id } }"
                    class="pv4-l pv3 mini-spacing"
                >
                    &lt; Account Details
                </router-link>

                <div class="flex justify-between items-center pb3 box-border-bottom">
                    <div>
                        <p class="f3 b">Sales Report</p>
                    </div>
                    <div>
                        <button @click="handleSalesRecords">Filter</button>
                    </div>
                </div>
            </div>
            <!--Sales Report sheet -->

            <!-- date, comparison & filter  -->
            <div class="w-90 center pt4">
                <div class="flex justify-between">
                    <div></div>

                    <div class="flex items-center flex-wrap justify-end items-center w-100 pt0-l pt3" style="gap: 1rem">
                        <div class="flex flex-wrap" style="gap: 1rem">
                            <span> Start date <input v-model="state.startDate" type="date" name="" class="input-style"/></span>
                            <span>
                                End date
                                <input
                                    @change="handleSalesRecords()"
                                    v-model="state.endDate"
                                    type="date"
                                    name=""
                                    class="input-style"
                            /></span>
                            <select @change="handleSalesRecords()" name="Filer" v-model="state.filter">
                                <option value="Daily">Daily</option>
                                <option value="Weekly">Weekly</option>
                                <option value="monthly">Monthly</option>
                                <option value="Annual">Annually</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <!-- date, comparison & filter  -->

            <!-- report of balance sheet -->
            <div class="w-90 center mt4">
                <!-- Business Details -->
                <div class="pa3 box-border">
                    <!-- <OrgData heading="Sales Report" /> -->

                    <!-- statement of profit/lose -->
                    <div class="tc pv4">
                        <div class="b" style="color: #132C8C;">
                            <!-- Daily Sales Report of Bob Adams for the period list Jan 2021-Dec 31st 2021 -->
                            {{ state.filter }} Report of {{ orgData.org }} as at {{ formatDateInLocal(state.endDate)
                            }}<span class="pr3"></span>
                        </div>
                    </div>

                    <!-- All, Sales, Expenses, Purchase & Other -->
                    <div class="scrollmenu w-90 center mt20">
                        <table class="w-100 tl mt3" cellspacing="0">
                            <thead>
                                <tr>
                                    <th class="pb3 pr3 bb b--black-20 tl">{{ tableHead[state.filter.toLowerCase()] }}</th>
                                    <th class="pb3 pr3 bb b--black-20 tl">Qty sold</th>
                                    <th class="pb3 pr3 bb b--black-20 tl">Number of customers</th>
                                    <th class="pb3 bb b--black-20 tl pr2">Revenue</th>
                                </tr>
                            </thead>

                            <tr v-for="result in state.result" :key="result.filter" class="font-w1">
                                <td class="pv3 pr3 bb b--black-20 tl">{{ result.filter }}</td>
                                <td class="pv3 pr3 bb b--black-20 tl">{{ result.qtySold }}</td>
                                <td class="pv3 pr3 bb b--black-20 tl ">
                                    {{ result.noOfCustomer }}
                                </td>
                                <td class="pv3 pr3 bb b--black-20 tl">
                                    {{ formatAmount(result.revenue, $store.state.Settings?.currency) }}
                                </td>
                            </tr>
                        </table>
                    </div>

                    <!-- pagination -->
                    <div class="w-90 center pb5">
                        <div class="flex justify-between items-center pa3 font-w1" style="background: #F6F8FE;">
                            <!-- <div class="flex items-center ">
                                <div class="pr3-l pr2">
                                    Showing
                                </div>
                                <div
                                    class="flex items-center pa1 box-border"
                                    style="border: 1px solid rgb(151, 145, 145); background: #ffffff;"
                                >
                                    4&nbsp; <img src="imgs/down-o.svg" alt="" />
                                </div>
                                <div class="pl3 db-l dn">items per page</div>
                            </div> -->
                            <!-- <div class="flex">
                                <div class="active-page-number">1</div>
                                <div class="pl2 pl3-l">2</div>
                                <div class="pl2 pl3-l active-page-number">Next</div>
                                <div class="pl2 pl3-l">End</div>
                            </div> -->
                        </div>
                    </div>
                    <!-- pagination -->

                    <!-- save as & export button -->
                    <div class="flex justify-between pv4">
                        <div></div>
                        <div class="w-auto-l w-100 flex">
                            <!-- <button class="flex items-center">
                                    Save as &nbsp; <img src="../../assets/images/down.svg" alt="" />
                                </button> -->
                            <!-- <button @click="downloadFile" class="flex items-center ml2">
                                Export &nbsp;
                                <img :src="require('@/assets/images/down.svg')" style="width: 10px" alt="" />
                            </button> -->
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </AdminWrapper>
</template>

<script>
import { useStore } from 'vuex'

import { computed, onMounted, reactive, watch } from 'vue'
import { formatAmount, formatDateInLocal, formatDateFilter, downloadCSV } from '@/utils/lib'
import AdminWrapper from '../../../layout/AdminWrapper.vue'
import OrgData from '@/components/OrgData'
import { useRouter } from 'vue-router'

export default {
    name: 'SalesReport ',
    components: { AdminWrapper, OrgData },

    setup() {
        const store = useStore()
        const orgData = computed(() => store.state.Auth.userData)
        const report = computed(() => store.state.Admin.getSalesReport)
        const route = useRouter()

        const month = new Date().getMonth()
        const year = new Date().getFullYear()
        const day = new Date().getDate()

        const tableHead = {
            monthly: 'Month',
            daily: 'Day',
            weekly: 'Week',
            annual: 'Year'
        }

        const downloadFile = () => {
            downloadCSV(state.result)
        }

        const state = reactive({
            startDate: '',
            endDate: '',
            filter: 'monthly',
            result: []
        })

        const handleSalesRecords = () => {
            if (state.filter.toLowerCase() === 'daily' && !state.startDate && !state.endDate) {
                state.startDate = new Date(year, month, 1)
                state.endDate = new Date(year, month, day)

                //Daily: 1st of current Month - Current date
                // console.log('daily')
            } else if (state.filter.toLowerCase() === 'weekly' && !state.startDate && !state.endDate) {
                // 1st week of the Year to current week. Week starts from Monday//
                state.startDate = new Date(year, 0, 1)
                state.endDate = new Date(year, month, day)
                // console.log('weekly')
            } else if (state.filter.toLowerCase() === 'monthly' && !state.startDate && !state.endDate) {
                // Monthly: Jan of the current year - Current month
                state.startDate = new Date(year, 0, 1)
                state.endDate = new Date(year, month, day)
                // console.log('monthly')
            } else if (state.filter.toLowerCase() === 'annual' && !state.startDate && !state.endDate) {
                // 2021 to current Year
                state.startDate = new Date(2021, 0, 1)
                state.endDate = new Date(year, month, day)
                // console.log('yearly')
            }
            if (state.startDate > state.endDate) {
                return store.dispatch('Alert/setAlert', {
                    message: 'Start date cannot be greater than end date',
                    status: false
                })
            }

            if (!state.filter) {
                return store.dispatch('Alert/setAlert', {
                    message: 'Filter is not selected',
                    status: false
                })
            }

            const query = `startDate=${state.startDate}&endDate=${state.endDate}&filter=${state.filter}`

            store.dispatch('Admin/getSalesReport', { id: route.currentRoute.value.params.id, query: query })

            // console.log(state.startDate, state.endDate, state.filter, 'state.startDate, state.endDate, state.filter')
        }

        const allDay = [
            {
                day: 'Monday',
                value: 1
            },
            {
                day: 'Tuesday',
                value: 2
            },
            {
                day: 'Wednesday',
                value: 3
            },
            {
                day: 'Thursday',
                value: 4
            },
            {
                day: 'Friday',
                value: 5
            },
            {
                day: 'Saturday',
                value: 6
            },
            {
                day: 'Sunday',
                value: 7
            }
        ]
        const allWeek = [
            {
                week: 'Week 1',
                value: 1
            },
            {
                week: 'Week 2',
                value: 2
            },
            {
                week: 'Week 3',
                value: 3
            },
            {
                week: 'Week 4',
                value: 4
            },
            {
                week: 'Week 5',
                value: 5
            },
            {
                week: 'Week 6',
                value: 6
            },
            {
                week: 'Week 7',
                value: 7
            },
            {
                week: 'Week 8',
                value: 8
            },
            {
                week: 'Week 9',
                value: 9
            },
            {
                week: 'Week 10',
                value: 10
            },
            {
                week: 'Week 11',
                value: 11
            },
            {
                week: 'Week 12',
                value: 12
            },
            {
                week: 'Week 13',
                value: 13
            },
            {
                week: 'Week 14',
                value: 14
            },
            {
                week: 'Week 15',
                value: 15
            },
            {
                week: 'Week 16',
                value: 16
            },
            {
                week: 'Week 17',
                value: 17
            },
            {
                week: 'Week 18',
                value: 18
            },
            {
                week: 'Week 19',
                value: 19
            },
            {
                week: 'Week 20',
                value: 20
            },
            {
                week: 'Week 21',
                value: 21
            },
            {
                week: 'Week 22',
                value: 22
            },
            {
                week: 'Week 23',
                value: 23
            },
            {
                week: 'Week 24',
                value: 24
            },
            {
                week: 'Week 25',
                value: 25
            },
            {
                week: 'Week 26',
                value: 26
            },
            {
                week: 'Week 27',
                value: 27
            },
            {
                week: 'Week 28',
                value: 28
            },
            {
                week: 'Week 29',
                value: 29
            },
            {
                week: 'Week 30',
                value: 30
            },
            {
                week: 'Week 31',
                value: 31
            },
            {
                week: 'Week 32',
                value: 32
            },
            {
                week: 'Week 33',
                value: 33
            },
            {
                week: 'Week 34',
                value: 34
            },
            {
                week: 'Week 35',
                value: 35
            },
            {
                week: 'Week 36',
                value: 36
            },
            {
                week: 'Week 37',
                value: 37
            },
            {
                week: 'Week 38',
                value: 38
            },
            {
                week: 'Week 39',
                value: 39
            },
            {
                week: 'Week 40',
                value: 40
            },
            {
                week: 'Week 41',
                value: 41
            },
            {
                week: 'Week 42',
                value: 42
            },
            {
                week: 'Week 43',
                value: 43
            },
            {
                week: 'Week 44',
                value: 44
            },
            {
                week: 'Week 45',
                value: 45
            },
            {
                week: 'Week 46',
                value: 46
            },
            {
                week: 'Week 47',
                value: 47
            },
            {
                week: 'Week 48',
                value: 48
            },
            {
                week: 'Week 49',
                value: 49
            },
            {
                week: 'Week 50',
                value: 50
            },
            {
                week: 'Week 51',
                value: 51
            },
            {
                week: 'Week 52',
                value: 52
            }
        ]
        const allMonth = [
            {
                month: 'January',
                value: 1
            },
            {
                month: 'February',
                value: 2
            },
            {
                month: 'March',
                value: 3
            },
            {
                month: 'April',
                value: 4
            },
            {
                month: 'May',
                value: 5
            },
            {
                month: 'June',
                value: 6
            },
            {
                month: 'July',
                value: 7
            },
            {
                month: 'August',
                value: 8
            },
            {
                month: 'September',
                value: 9
            },
            {
                month: 'October',
                value: 10
            },
            {
                month: 'November',
                value: 11
            },
            {
                month: 'December',
                value: 12
            }
        ]
        const allYear = [
            {
                year: '2021',
                value: 2021
            },
            {
                year: '2022',
                value: 2022
            },
            {
                year: '2023',
                value: 2023
            },
            {
                year: '2024',
                value: 2024
            },
            {
                year: '2025',
                value: 2025
            },
            {
                year: '2026',
                value: 2026
            },
            {
                year: '2027',
                value: 2027
            },
            {
                year: '2028',
                value: 2028
            },
            {
                year: '2029',
                value: 2029
            },
            {
                year: '2030',
                value: 2030
            }
        ]

        const getDailyResult = report => {
            //    const dayResult = allDay.filter((day => day.value >= report[0].day)).map(( day => {
            if (report && report.length) {
                const dayResult = report.map(day => {
                    if (day) {
                        return {
                            filter: day.day,
                            qtySold: day.quantitySold,
                            noOfCustomer: day.numberOfCustomers,
                            revenue: day.revenue
                        }
                    } else {
                        return {
                            filter: day,
                            qtySold: 0,
                            noOfCustomer: 0,
                            revenue: 0
                        }
                    }
                })
                // console.log(dayResult, 'day result')
                state.result = dayResult
                // const foundDay = report.find(r => r.day !== day.value)
            }
            return {
                filter: '',
                qtySold: 0,
                noOfCustomer: 0,
                revenue: 0
            }
        }

        const getWeeklyResult = report => {
            //    const dayResult = allDay.filter((day => day.value >= report[0].day)).map(( day => {
            if (report && report.length) {
                const weekResult = report.map(week => {
                    if (week) {
                        return {
                            filter: week.week,
                            qtySold: week.quantitySold,
                            noOfCustomer: week.numberOfCustomers,
                            revenue: week.revenue
                        }
                    } else {
                        return {
                            filter: '',
                            qtySold: 0,
                            noOfCustomer: 0,
                            revenue: 0
                        }
                    }
                })
                console.log(weekResult, 'day result')
                state.result = weekResult
            }
            return {
                filter: '',
                qtySold: 0,
                noOfCustomer: 0,
                revenue: 0
            }
        }

        const getMonthlyResult = report => {
            //    const dayResult = allDay.filter((day => day.value >= report[0].day)).map(( day => {
            if (report && report.length) {
                const monthlyResult = report.map(month => {
                    if (month) {
                        return {
                            filter: month.month,
                            qtySold: month.quantitySold,
                            noOfCustomer: month.numberOfCustomers,
                            revenue: month.revenue
                        }
                    } else {
                        return {
                            filter: '',
                            qtySold: 0,
                            noOfCustomer: 0,
                            revenue: 0
                        }
                    }
                })
                // console.log(monthlyResult, 'day result')
                state.result = monthlyResult
            }
            return {
                filter: '',
                qtySold: 0,
                noOfCustomer: 0,
                revenue: 0
            }
        }

        const getYearlyResult = report => {
            // console.log(year.value)
            const yearResult = allYear
                .filter(year => year.value <= report[0].year)
                .map(year => {
                    if (report && report.length) {
                        const foundYear = report.find(r => r.year === year.value)
                        if (foundYear) {
                            return {
                                filter: year.year,
                                qtySold: foundYear.quantitySold,
                                noOfCustomer: foundYear.numberOfCustomers,
                                revenue: foundYear.revenue
                            }
                        } else {
                            return {
                                filter: year.year,
                                qtySold: 0,
                                noOfCustomer: 0,
                                revenue: 0
                            }
                        }
                    }
                    return {
                        filter: year.year,
                        qtySold: 0,
                        noOfCustomer: 0,
                        revenue: 0
                    }
                })
            // console.log(yearResult, 'yearly result')
            state.result = yearResult
        }

        const changeDate = () => {
            state.startDate = formatDateFilter(state.startDate)
            state.endDate = formatDateFilter(state.endDate)
            const query = `startDate=${state.startDate}&endDate=${state.endDate}&filter=${state.filter}`

            store.dispatch('Admin/getSalesReport', { id: route.currentRoute.value.params.id, query: query })
        }

        watch(
            () => report.value,
            () => {
                if (report.value) {
                    switch (state.filter.toLowerCase()) {
                        case 'daily':
                            getDailyResult(report.value)
                            break
                        case 'weekly':
                            getWeeklyResult(report.value)
                            break
                        case 'monthly':
                            getMonthlyResult(report.value)
                            break
                        case 'annual':
                            getYearlyResult(report.value)
                            break
                        default:
                            break
                    }
                }
            }
        )

        onMounted(() => {
            let date = new Date()
            date.setFullYear(date.getFullYear() - 1)

            const startDate = date
            const endDate = new Date(year, month, day)
            const filter = 'monthly'

            const query = `startDate=${startDate}&endDate=${endDate}&filter=${filter}`

            state.startDate = formatDateFilter(startDate)
            state.endDate = formatDateFilter(endDate)

            store.dispatch('Admin/getSalesReport', { id: route.currentRoute.value.params.id, query: query })
        })

        return {
            downloadFile,
            orgData,
            report,
            state,
            handleSalesRecords,
            tableHead,
            formatDateInLocal,
            formatDateFilter,
            formatAmount,
            changeDate
        }

        const filterReport = query => {
            store.dispatch('Reporting/getSalesReports', query)
        }

        return {
            orgData,
            filterReport
        }
    }
}
</script>

<style>
.Filter-btn {
    padding: 12 !important;
}
</style>
